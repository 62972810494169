<template>
  <div>
    <vue-headful :title="title" />
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
      v-model="connectingModal.visible"
    >
      <div class="d-block text-center">
        <b-img
          v-if="connectingModal.image == 'xero'"
          class="icon"
          rounded="circle"
          :src="require('@/assets/images/xero.png')"
        />
        <b-img
          v-if="connectingModal.image == 'quickBooks'"
          class="icon"
          rounded="circle"
          :src="require('@/assets/images/qb.png')"
        />
        <b-img
          v-if="connectingModal.image == 'sageOne'"
          class="icon"
          rounded="circle"
          :src="require('@/assets/images/sage.png')"
        />
        <h3 class="mt-3">{{ connectingModal.message }}</h3>
        <h4 class="text-muted">Please Wait...</h4>
        <Busy primary :size="3" :margin="2" />
      </div>
    </b-modal>

    <b-modal
      id="extractFailureModal"
      ref="extractFailureModal"
      hide-header
      ok-only
      @ok="handleHidden"
      @hidden="handleHidden"
      no-fade
    >
      <div class="d-block text-center">
        <b-img class="icon" rounded="circle" :src="require('@/assets/images/reducer_pink.png')" />
        <h3 class="mt-3">Error</h3>
        <h4 class="text-muted">The organisation was not connected to Reducer</h4>
      </div>
    </b-modal>
    <b-modal
      id="extractSuccessModal"
      ref="extractSuccessModal"
      hide-header
      ok-only
      @ok="handleHidden"
      @hidden="handleHidden"
      no-fade
    >
      <div class="d-block text-center">
        <b-img class="icon" rounded="circle" :src="require('@/assets/images/reducer_pink.png')" />
        <h3 class="mt-3">Success</h3>
        <h4 class="text-muted">The organisation was successfully connected to Reducer</h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import ExtractHelper from "@/helper/extracthelper";
import Busy from "@/components/Busy";

export default {
  name: "ExtractCallback",
  async mounted() {
    var type = this.$route.params.platform,
      params = this.$route.query;

    this.connectingModal.message = `Initiating ${ExtractHelper.typeDescription(type)} connection`;
    this.connectingModal.image = type;
    this.connectingModal.visible = true;

    this.completeConnection(type, params);
  },
  data() {
    return {
      title: "Reducer",
      connectingModal: {
        image: null,
        message: null,
        visible: false
      }
    };
  },
  components: {
    Busy
  },
  methods: {
    handleHidden() {
      this.$router.replace({ name: "account" });
    },
    validXero(args) {
      // xero - {"oauth_token":"","oauth_verifier":"","org":""}
      return args["oauth_token"] && args["oauth_verifier"] && args["org"];
    },
    validQuickBooks(args) {
      // quickbooks -  {"state":"","code":"","realmId":""}
      return args["state"] && args["code"] && args["realmId"];
    },
    async completeConnection(type, params) {
      try {
        var paramString = encodeURI(JSON.stringify(params));
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}connections/complete?platform=${type}&callback=account&params=${paramString}`
        );
        Console.log(response);
        if (response.data.status === "success") {
          this.showSuccess();
        } else {
          this.showFailure();
        }
      } catch {
        this.showFailure();
      }
    },
    showSuccess() {
      this.connectingModal.visible = false;
      this.$refs.extractSuccessModal.show();
    },
    showFailure() {
      this.connectingModal.visible = false;
      this.$refs.extractFailureModal.show();
    }
  }
};
</script>

